import React from 'react'
import styled from 'styled-components'

import Logout from '../components/Auth/Logout'

const Header = () => (
  <div className='clearfix'>
    <StyledLogout />
  </div>
)

const StyledLogout = styled(Logout)`
  float: right;
  margin-top: 25px;
`

export default Header
