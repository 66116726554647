import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'

import { signOut } from '../../redux/reducers/auth'

const Logout = ({ signOut, authenticated, ...rest }) =>
  authenticated && (
    <Button icon='logout' onClick={signOut} {...rest}>
      Logout
    </Button>
  )

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated
})

const mapDispatchToProps = {
  signOut
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout)
