import authService from '../../services/authService'
import rolesService from '../../services/rolesService'

export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_CLIENT = 'SET_CLIENT'
export const LOAD_ROLES = 'LOAD_ROLES'

const INITIAL_STATE = {
  initialized: false,
  authenticated: false,
  error: false,
  client: null,
  profile: null,
  roles: []
}

const createInitialState = () => {
  const state = INITIAL_STATE
  const profile = authService.getProfile()
  state.authenticated = !!profile
  return state
}

export const authReducer = (state = createInitialState(), { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        authenticated: true,
        error: false,
        profile: payload
      }
    case LOGOUT:
      return {
        ...state,
        profile: null,
        authenticated: false,
        roles: []
      }
    case LOGIN_ERROR:
      return {
        ...state,
        authenticated: false,
        error: true,
        profile: null,
        roles: []
      }
    case SET_CLIENT:
      return {
        ...state,
        initialized: true,
        client: payload
      }
    case LOAD_ROLES:
      return {
        ...state,
        roles: payload
      }
    default:
      return state
  }
}

export const login = profile => {
  return { type: LOGIN, payload: profile }
}

export const logout = () => {
  return { type: LOGOUT }
}

export const loginError = () => {
  return { type: LOGIN_ERROR }
}

export const setClient = client => {
  return { type: SET_CLIENT, payload: client }
}

export const loadRoles = roles => {
  return { type: LOAD_ROLES, payload: roles }
}

const validateEmail = email => {
  const domain = email.split('@')[1]
  return ['escale.com.br', 'umbrellacorretora.com.br'].indexOf(domain) >= 0
}

const onChangeLogin = (dispatch, client) => async () => {
  if (!client) return authService.init(onReady(dispatch))

  const isSignedIn = client.isSignedIn.get()
  if (isSignedIn) {
    const guser = client.currentUser.get()
    const profile = {
      token: guser.getAuthResponse().id_token,
      name: guser.getBasicProfile().getName(),
      email: guser.getBasicProfile().getEmail(),
      profile_photo: guser.getBasicProfile().getImageUrl()
    }
    if (validateEmail(profile.email)) {
      authService.setProfile(profile)
      dispatch(login(profile))
    } else {
      client.signOut()
      dispatch(loginError())
    }
  } else {
    authService.clearProfile()
    dispatch(logout())
  }
}

const onReady = dispatch => client => {
  dispatch(setClient(client))
  onChangeLogin(dispatch, client)()
  client.isSignedIn.listen(onChangeLogin(dispatch, client))
}

export const initializeAuthentication = () => async dispatch => {
  authService.init(onReady(dispatch))
}

export const signIn = () => (dispatch, getState) => {
  const { auth } = getState()
  auth.client.signIn()
}

export const signOut = () => (dispatch, getState) => {
  const { auth } = getState()
  auth.client.signOut()
  return dispatch({
    type: LOGOUT
  })
}

export const getUserRoles = operationName => async dispatch => {
  if (operationName) {
    const profile = authService.getProfile()
    const result = await rolesService.findAll(operationName, profile.email)
    dispatch(loadRoles(result.data))
  }
}
