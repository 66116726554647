import axios from 'axios'
import _ from 'lodash'
import fields from './fields.json'
import authService from './authService'

const {
  REACT_APP_LEAD_API,
  REACT_APP_BRAND_NAME,
  REACT_APP_OPERATION_NAME
} = process.env

class FormService {
  sendForm (formData, roles, token) {
    const lead = this.formToLead(formData, roles)
    const url = `${REACT_APP_LEAD_API}/gauth/${REACT_APP_OPERATION_NAME}/${REACT_APP_BRAND_NAME}/leads`
    return axios.post(url, lead, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  getFields (roles) {
    if (this.isNegociator(roles)) return fields[process.env.REACT_APP_ENV].corretor
    else return fields[process.env.REACT_APP_ENV].conector
  }

  isNegociator (roles) {
    return _.some(roles, r => r === 'Corretor')
  }

  isConector (roles) {
    return _.some(roles, r => r === 'Conector')
  }

  formToLead (formData, roles) {
    const fields = this.getFields(roles)
    const findResult = _.find(fields, x => x.value === formData.referrer)
    const referrerLabel = findResult.label

    const profile = authService.getProfile()
    const assigneeEmail = REACT_APP_OPERATION_NAME === 'development'
      ? 'stars@escale.com.br'
      : profile.email

    const lead = {
      data: {
        atribuido_email: assigneeEmail,
        cliente_nome: formData.client_name,
        cliente_telefone: formData.phone_number,
        cliente_email: formData.email,
        modalidade_recebida: formData.modality,
        assunto: `Indicação de Lead de ${referrerLabel}`,
        descricao: `Lead indicado por ${referrerLabel}`
      }
    }

    if (this.isNegociator(roles)) lead.data.corretor = formData.referrer
    else lead.data.conector = formData.referrer

    return lead
  }
}

export default new FormService()
