import React from 'react'
import styled from 'styled-components'
import { Form, Input, Button, Select } from 'antd'
import MaskedInput from 'react-text-mask'

import { phoneMask } from './masks'

const Body = props => {
  const { values, errors, touched, handleChange, handleSelectChange } = props
  const { handleBlur, isSubmitting, referrers } = props

  return (
    <>
      <Form.Item
        label='Nome do cliente'
        validateStatus={errors.client_name && touched.client_name && 'error'}
        help={errors.client_name && touched.client_name && errors.client_name}
      >
        <Input
          name='client_name'
          placeholder='Digite aqui o nome...'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.client_name}
          size='large'
        />
      </Form.Item>

      <Form.Item
        label='Telefone para contato'
        validateStatus={errors.phone_number && touched.phone_number && 'error'}
        help={errors.phone_number && touched.phone_number && errors.phone_number}
      >
        <MaskedInput
          className='ant-input ant-input-lg'
          placeholder='(__) _ ____-____'
          name='phone_number'
          type='tel'
          mask={phoneMask}
          value={values.phone_number}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item
        label='Email'
        validateStatus={errors.email && touched.email && 'error'}
        help={errors.email && touched.email && errors.email}
      >
        <Input
          name='email'
          placeholder='cliente@email.com.br'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          size='large'
        />
      </Form.Item>

      <Form.Item
        label='Modalidade'
        validateStatus={errors.modality && touched.modality && 'error'}
        help={errors.modality && touched.modality}
      >
        <Select
          name='modality'
          size='large'
          showSearch
          filterOption={false}
          value={values.modality}
          onChange={handleSelectChange('modality')}
          errors={errors.modality}
          hasFeedbackMessage
        >
          {modalities.map(({ value, label }) => (
            <Select.Option key={value} value={value} data-slug={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Indicado por'
        validateStatus={errors.referrer && touched.referrer && 'error'}
        help={errors.referrer && touched.referrer && errors.referrer}
      >
        <Select
          name='referrer'
          size='large'
          showSearch
          filterOption={false}
          value={values.referrer}
          onChange={handleSelectChange('referrer')}
          errors={errors.referrer}
          hasFeedbackMessage
        >
          {referrers.map(({ value, label }) => (
            <Select.Option key={value} value={value} data-slug={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <StyledButton type='primary' htmlType='submit' size='large' disabled={isSubmitting}>
        Enviar formulário
      </StyledButton>
    </>
  )
}

const StyledButton = styled(Button)`
  width: 100%;

  &:disabled {
    background: gray;
  }
`

const modalities = [{
  label: 'A- PME/CNPJ (2 a 29 vidas)',
  value: 'pme'
}, {
  label: 'B- MEI',
  value: 'mei'
}, {
  label: 'C- Adesão',
  value: 'adesão'
}, {
  label: 'D- Familiar',
  value: 'familiar'
}, {
  label: 'E- Individual',
  value: 'individual'
}, {
  label: 'F- Empresarial (30 a 99 vidas)',
  value: 'empresarial'
}, {
  label: 'G- Corporativo (+100 vidas)',
  value: 'corporativo'
}, {
  label: 'Z - Indicação',
  value: 'indicação'
}]

export default Body
