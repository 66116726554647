import styled, { css } from 'styled-components'

const sizes = {
  SMALL: 540,
  MEDIUM: 720,
  LARGE: 1140,
  LARGE_TEXT: 814,
  GUTTER: 30
}

export const device = {
  MOBILE: `(max-width: ${sizes.SMALL}px)`,
  TABLET: `(min-width: ${sizes.MEDIUM}px)`,
  DESKTOP: `(min-width: ${sizes.LARGE}px)`
}

const gridBreakpoints = [
  {
    screen: 576,
    maxWidth: sizes.SMALL
  },
  {
    screen: 768,
    maxWidth: sizes.MEDIUM
  },
  {
    screen: 1024,
    maxWidth: sizes.LARGE
  }
]

const cssBreakpoints = gridBreakpoints.map(
  item => css`
    @media (min-width: ${item.screen}px) {
      max-width: ${item.maxWidth}px;
    }
  `
)

export const Container = styled.div`
  transition: max-width 0.4s;
  width: 100%;
  padding-right: ${sizes.GUTTER / 2}px;
  padding-left: ${sizes.GUTTER / 2}px;
  margin-right: auto;
  margin-left: auto;
  ${cssBreakpoints}

  &.text-container {
    @media ${device.TABLET} {
      max-width: ${sizes.MEDIUM}px;
    }
    @media ${device.DESKTOP} {
      max-width: ${sizes.LARGE_TEXT}px;
    }
  }
`
