import storageService from './storageService'

const GOOGLE_CLIENT_ID = '422159826794-v0fod5a0bik6ranstu7t7tbi33o65rl4.apps.googleusercontent.com'
const PROFILE_KEY = 'AUTH_PROFILE'

class AuthService {
  async init (onReady) {
    window.gapi.load('client:auth2', async () => {
      await window.gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: 'profile email'
      })
      onReady(window.gapi.auth2.getAuthInstance())
    })
  }

  setProfile (profile) {
    storageService.setItem(PROFILE_KEY, JSON.stringify(profile))
  }

  getProfile () {
    return JSON.parse(storageService.getItem(PROFILE_KEY))
  }

  clearProfile () {
    storageService.removeItem(PROFILE_KEY)
  }
}

export default new AuthService()
