import React from 'react'

import { Container } from './layout'
import { Result, Button } from 'antd'
import { FORM } from '../routes/paths'

const Success = ({ history }) => (
  <Container>
    <Result
      status='success'
      title='Lead registrado com sucesso'
      extra={[
        <Button key='form' type='primary' size='large' onClick={_ => history.push(FORM)}>
          Deseja adicionar um novo lead?
        </Button>
      ]}
    />
  </Container>
)

export default Success
