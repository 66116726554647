import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { notification } from 'antd'

import validators from './validators'
import FormBody from './Body'
import { Container } from '../layout'
import { SUCCESS } from '../../routes/paths'
import formService from '../../services/FormService'

const GenericForm = ({ handleSubmit, validationSchema, initialValues, children }) => (
  <Formik onSubmit={handleSubmit} validationSchema={validationSchema} initialValues={initialValues}>
    {props => {
      const handleSelectChange = field => value => props.setFieldValue(field, value)
      return (
        <form onSubmit={props.handleSubmit}>
          {React.Children.map(children, child =>
            React.cloneElement(child, { ...props, handleSelectChange })
          )}
        </form>
      )
    }}
  </Formik>
)

const Form = ({ history, roles, profile }) => {
  const [referrers, setReferrers] = useState([])
  useEffect(
    _ => {
      const fields = formService.getFields(roles)
      setReferrers(fields)
    },
    [roles]
  )

  const validationSchema = Yup.object().shape({
    client_name: Yup.string().required('Favor inserir o nome do cliente'),
    phone_number: validators.phoneNumberValidator('Favor inserir o telefone de contato'),
    email: Yup.string()
      .email('O email é inválido')
      .required('Favor inserir o email de contato'),
    modality: Yup.string().required('Favor escolher uma modalidade'),
    referrer: Yup.string().required('Favor inserir quem está indicando o lead')
  })

  const initialValues = {
    client_name: '',
    phone_number: '',
    email: '',
    modality: '',
    referrer: ''
  }

  const [error, setError] = useState(null)
  const errorMessage = {
    message: 'Algo deu errado ao submeter o form',
    description: error && error.toString()
  }
  const handleSubmit = (data, { setSubmitting }) =>
    formService
      .sendForm(data, roles, profile.token)
      .then(_ => history.push(SUCCESS))
      .catch(setError)
      .then(_ => setSubmitting(false))

  return (
    <Container>
      {error && notification.error(errorMessage)}
      <GenericForm
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
      >
        <FormBody referrers={referrers} />
      </GenericForm>
    </Container>
  )
}

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles,
  profile: auth.profile
})

export default connect(mapStateToProps)(Form)
