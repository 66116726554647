import authService from './authService'
import axios from 'axios'

const REACT_APP_OPERATION_API = process.env.REACT_APP_OPERATION_API
class RoleService {
  findAll (operationName, email) {
    const { token } = authService.getProfile()
    const url = `${REACT_APP_OPERATION_API}/operations/${operationName}/user-roles/${email}`
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}

export default new RoleService()
