import { connect } from 'react-redux'

import { initializeAuthentication } from '../redux/reducers/auth'

const Authenticator = ({ initialized, initializeAuthentication }) => {
  if (!initialized) initializeAuthentication()

  return null
}

const mapStateToProps = state => ({
  initialized: state.auth.initialized
})

const mapDispatchToProps = {
  initializeAuthentication
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authenticator)
