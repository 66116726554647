import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import PrivateRoute from '../components/PrivateRoute'
import Login from '../components/Auth/Login'
import Form from '../components/Form'
import Success from '../components/Success'

import { LOGIN, FORM, SUCCESS } from './paths'

const Routes = () => (
  <Router>
    <Switch>
      <Route path={LOGIN} component={Login} />
      <PrivateRoute path={FORM} component={Form} />
      <PrivateRoute path={SUCCESS} component={Success} />
      <Redirect to={LOGIN} />
    </Switch>
  </Router>
)

export default Routes
