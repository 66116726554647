import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Alert, Button, Spin } from 'antd'
import styled from 'styled-components'

import { signIn, getUserRoles } from '../../redux/reducers/auth'
import { FORM } from '../../routes/paths'
import escaleLogo from '../../assets/images/escale-logo.png'
import { device } from '../layout'

const REACT_APP_OPERATION_SETUP_KEY = process.env.REACT_APP_OPERATION_SETUP_KEY

const Login = props => {
  const { initialized, authenticated, loginError, signIn } = props

  if (authenticated) {
    props.getUserRoles(REACT_APP_OPERATION_SETUP_KEY)
    return <Redirect to={FORM} />
  }

  return (
    <StyledLogin>
      <Container>
        <Logo src={escaleLogo} />
        {initialized ? <LoginButton handleClick={signIn} /> : <Spin />}
        {loginError && (
          <Alert message='Erro no Login. Por favor tente novamente.' type='error' closable />
        )}
      </Container>
    </StyledLogin>
  )
}

const LoginButton = ({ handleClick }) => (
  <StyledButton onClick={handleClick} type='primary' icon='google' size='large'>
    LOGIN
  </StyledButton>
)

const StyledButton = styled(Button)`
  width: 50%;
  font-size: 14px;

  @media ${device.TABLET} {
    font-size: 20px;
  }
`

const Logo = styled.img`
  width: 50%;
  margin-bottom: 20px;
`

const StyledLogin = styled.div`
  background: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
`

const Container = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`

const mapStateToProps = state => ({
  initialized: state.auth.initialized,
  authenticated: state.auth.authenticated,
  loginError: state.auth.error
})

const mapDispatchToProps = {
  signIn,
  getUserRoles
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
